<template>
  <v-list
    expand
    nav
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="(item, i) in items">
      <default-list-group
        v-if="item.items"
        :key="`group-${i}`"
        :item="item"
      />
      <template v-else>
        <template v-if="permission(item)">
          <default-list-item
              :key="`item-${i}`"
              :item="item"
          />
        </template>
      </template>
    </template>
  </v-list>
</template>

<script>
  export default {
    name: 'DefaultList',
    computed:{

    },

    components: {
      DefaultListGroup: () => import('./ListGroup'),
      DefaultListItem: () => import('./ListItem'),
    },

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },
    methods:{
      permission(item){
        let rolesArr= JSON.parse(sessionStorage.getItem('userInfo')).roles
        let hasRoles = item.auth

        let sb = new Set(hasRoles);

        let intersect = rolesArr.filter(x => sb.has(x));

        return  intersect.length > 0

      }
    }
  }
</script>
